import { Permission } from '@campxdev/shared'
import { Tracking } from 'pages'
import People from 'pages/Settings/People/People'
import { Navigate } from 'react-router-dom'

export const settingRoutes = [
  {
    index: true,
    element: <Navigate to={'application-profiles'} />,
  },
  {
    element: <Tracking />,
    path: 'tracking',
  },
  {
    path: 'application-profiles',
    element: <People />,
    permissionKey: Permission.CAN_MANAGE_COMMUTE_X_PROFILE_PERMISSIONS_VIEW,
  },
]
