import { Permission } from '@campxdev/shared'
import {
  ActivityLogs,
  Archives,
  BoardingPoint,
  BusRoutes,
  Dashboard,
  Payments,
  Registrations,
  Report,
  Tracking,
} from 'pages'
import ReportsViewLayout from 'pages/Reports/ReportsViewLayout/ReportsViewLayout'

import { Navigate } from 'react-router-dom'
export const baseRoutes = [
  {
    index: true,
    element: <Navigate to={'routes'} />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    element: <Dashboard />,
    path: '/dashboard',
  },
  {
    element: <Tracking />,
    path: '/tracking',
  },
  {
    element: <BusRoutes />,
    path: '/routes',
    permissionKey: Permission.CAN_COMMUTE_X_ROUTES_VIEW,
  },
  {
    element: <Registrations />,
    path: '/registrations',
    permissionKey: Permission.CAN_COMMUTE_X_REGISTRATIONS_VIEW,
  },
  {
    path: '/reports/*',
    element: <ReportsViewLayout />,
    permissionKey: Permission.CAN_COMMUTE_X_REGISTRATIONS_VIEW,
  },
  {
    element: <Payments />,
    path: '/payments',
  },
  {
    element: <ActivityLogs />,
    path: '/activity_logs',
  },
  {
    element: <Archives />,
    path: '/archives',
  },
  {
    element: <BoardingPoint />,
    path: '/routes/:id',
    permissionKey: Permission.CAN_COMMUTE_X_REGISTRATIONS_VIEW,
  },
]
