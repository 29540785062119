import { lazy } from 'react'

const Dashboard = lazy(() => import('./Dashboard/Dashboard'))
const ActivityLogs = lazy(() => import('./ActivityLogs'))
const Archives = lazy(() => import('./Archives'))
const BoardingPoint = lazy(() => import('./BoardingPoint'))
const BusRoutes = lazy(() => import('./BusRoutes'))
const Payments = lazy(() => import('./Payments'))
const Registrations = lazy(() => import('./Registrations'))
const Report = lazy(() => import('./Reports'))
const Tracking = lazy(() => import('./Settings/Tracking'))

export {
  Dashboard,
  ActivityLogs,
  Archives,
  BoardingPoint,
  BusRoutes,
  Payments,
  Registrations,
  Report,
  Tracking,
}
