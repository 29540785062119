import FeeDuesReport from './ReportPages/FeeDuesReport'
import FeePaymentsReport from './ReportPages/FeePaymentsReport'

export const links = [
  {
    title: 'Payments',
    items: [
      {
        label: 'Fee Dues Report',
        to: `fee-dues-report`,
        component: FeeDuesReport,
      },
      {
        label: 'Fee Payments Report',
        to: `fee-payments-report`,
        component: FeePaymentsReport,
      },
    ],
  },
]
