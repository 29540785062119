import {
  ErrorBoundary,
  LayoutWrapper,
  SideMenuHeader,
  withRouteWrapper,
} from '@campxdev/shared'
import AppLayout from 'layouts/AppLayout'
import { appMenu } from 'layouts/appMenu'
import { SettingsMenu } from 'layouts/settingsMenu'
import { Outlet } from 'react-router-dom'

import { baseRoutes } from './baseRoutes'
import { settingRoutes } from './settingRoutes'

export const mainRoutes = [
  {
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: (
          <LayoutWrapper menu={appMenu}>
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </LayoutWrapper>
        ),
        children: withRouteWrapper(baseRoutes),
      },
      {
        path: 'settings',
        element: (
          <LayoutWrapper
            menu={SettingsMenu}
            sideMenuHeader={
              <SideMenuHeader path="/routes" title={'Configurations'} />
            }
          >
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </LayoutWrapper>
        ),
        children: withRouteWrapper(settingRoutes),
      },
    ],
  },

  {
    path: '*',
    element: (
      <h1 style={{ textAlign: 'center', marginTop: '50px' }}>Page Not Found</h1>
    ),
  },
]
