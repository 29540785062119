import moment from 'moment'
import { fetchTransportRegistration } from 'pages/Registrations/services'
import * as XLSX from 'xlsx'
import { fetchFeeDuesReport } from '../service'

export async function feePaymentsExport(filters) {
  const data = await fetchTransportRegistration({
    ...filters,
    skip: undefined,
    limit: undefined,
  })
  if (!data) {
    alert('No Data Found')
    return
  }

  const excelData = data?.registrations?.map((item, index) => ({
    'Admission Id': item?.admissionId,
    Name: item?.feeDue?.admissionSubValue?.fullName,
    'Roll No': item?.feeDue?.admissionSubValue?.rollNo,
    Batch: item?.feeDue?.admissionSubValue?.batch,
    Year: item?.year,
    'Route Name': item?.route?.routeName,
    'Total Fee': item?.feeDue?.payableAmount,
    'Total Paid': item?.feeDue?.debit,
    'Total Due': item?.feeDue?.totalDue,
  }))

  const fileName = `Fee Payments Report.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(
    wb,
    ws,
    `${moment().format('DD-MM-YYYY')} - Users`,
  )
  XLSX.writeFile(wb, fileName)
}

export async function feeDuesExport(filters) {
  const data = await fetchFeeDuesReport({
    ...filters,
    skip: undefined,
    limit: undefined,
  })
  if (!data) {
    alert('No Data Found')
    return
  }

  const excelData = data?.feeDue?.map((item, index) => ({
    'Admission Id': item?.admissionId,
    Name: item?.admissionSubValue?.fullName,
    'Roll No': item?.admissionSubValue.rollNo,
    Batch: item?.admissionSubValue.batch,
    Year: item.registration?.year,
    'Total Fee': item?.payableAmount,
    'Total Paid': item?.debit,
    'Total Due': item?.totalDue,
  }))

  const fileName = `Fee Due Report.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(
    wb,
    ws,
    `${moment().format('DD-MM-YYYY')} - Users`,
  )
  XLSX.writeFile(wb, fileName)
}
