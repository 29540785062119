import { Permission } from '@campxdev/shared'
import {
  ConfigurationIcon,
  RegistrationIcon,
  ReportsIcon,
  RoutesIcon
} from './icons'

export const appMenu = [
  // {
  //   title: 'Dashboard',
  //   path: 'dashboard',
  //   icon: DashboardIcon,
  //   // permissionKey: Permission.CAN_DASHBOARD_VIEW,
  // },
  // {
  //   title: 'Tracking',
  //   path: 'tracking',
  //   icon: TrackingIcon,
  //   // permissionKey: Permission.CAN_DASHBOARD_VIEW,
  // },
  {
    title: 'Routes',
    path: 'routes',
    icon: RoutesIcon,
    permissionKey: Permission.CAN_COMMUTE_X_ROUTES_VIEW,
  },
  {
    title: 'Reports',
    path: '/reports',
    icon: ReportsIcon,
    permissionKey: Permission.CAN_COMMUTE_X_REGISTRATIONS_VIEW,
  },
  {
    title: 'Registrations',
    path: 'registrations',
    icon: RegistrationIcon,
    permissionKey: Permission.CAN_COMMUTE_X_REGISTRATIONS_VIEW,
  },
  // {
  //   title: 'Payments',
  //   path: 'payments',
  //   icon: PaymentsIcon,
  //   // permissionKey: Permission.CAN_DASHBOARD_VIEW,
  // },
  // {
  //   title: 'Reports',
  //   path: 'reports',
  //   icon: ReportsIcon,
  //   // permissionKey: Permission.CAN_DASHBOARD_VIEW,
  // },
  // {
  //   title: 'Activity logs',
  //   path: 'activity_logs',
  //   icon: ActivitiesIcon,
  //   // permissionKey: Permission.CAN_DASHBOARD_VIEW,
  // },
  // {
  //   title: 'Archives',
  //   path: 'archives',
  //   icon: ArchivesIcon,
  //   // permissionKey: Permission.CAN_DASHBOARD_VIEW,
  // },
  {
    title: 'Configuration',
    path: 'settings',
    icon: ConfigurationIcon,
    permissionKey: Permission.CAN_MANAGE_COMMUTE_X_SETTINGS,
  },
]
