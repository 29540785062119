import { Permission } from '@campxdev/shared'
import { AdmissionsIcon, TrackingIcon } from './icons'

const basePath = '/settings'

export const SettingsMenu = [
  // {
  //   title: 'Tracking',
  //   path: 'tracking',
  //   icon: TrackingIcon,
  // },
  {
    path: basePath + '/application-profiles',
    title: 'People',
    icon: AdmissionsIcon,
    permissionKey: Permission.CAN_MANAGE_COMMUTE_X_PROFILE_PERMISSIONS_VIEW,
  },
]
