export const DashboardIcon = () => {
  return (
    <svg
      id="apps_2_"
      data-name="apps (2)"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_1377"
        data-name="Path 1377"
        d="M4.667,0h-2A2.667,2.667,0,0,0,0,2.667v2A2.667,2.667,0,0,0,2.667,7.333h2A2.667,2.667,0,0,0,7.333,4.667v-2A2.667,2.667,0,0,0,4.667,0ZM6,4.667A1.333,1.333,0,0,1,4.667,6h-2A1.333,1.333,0,0,1,1.333,4.667v-2A1.333,1.333,0,0,1,2.667,1.333h2A1.333,1.333,0,0,1,6,2.667Z"
        fill="#fff"
      />
      <path
        id="Path_1378"
        data-name="Path 1378"
        d="M17.667,0h-2A2.667,2.667,0,0,0,13,2.667v2a2.667,2.667,0,0,0,2.667,2.667h2a2.667,2.667,0,0,0,2.667-2.667v-2A2.667,2.667,0,0,0,17.667,0ZM19,4.667A1.333,1.333,0,0,1,17.667,6h-2a1.333,1.333,0,0,1-1.333-1.333v-2a1.333,1.333,0,0,1,1.333-1.333h2A1.333,1.333,0,0,1,19,2.667Z"
        transform="translate(-4.333)"
        fill="#fff"
      />
      <path
        id="Path_1379"
        data-name="Path 1379"
        d="M4.667,13h-2A2.667,2.667,0,0,0,0,15.667v2a2.667,2.667,0,0,0,2.667,2.667h2a2.667,2.667,0,0,0,2.667-2.667v-2A2.667,2.667,0,0,0,4.667,13ZM6,17.667A1.333,1.333,0,0,1,4.667,19h-2a1.333,1.333,0,0,1-1.333-1.333v-2a1.333,1.333,0,0,1,1.333-1.333h2A1.333,1.333,0,0,1,6,15.667Z"
        transform="translate(0 -4.333)"
        fill="#fff"
      />
      <path
        id="Path_1380"
        data-name="Path 1380"
        d="M17.667,13h-2A2.667,2.667,0,0,0,13,15.667v2a2.667,2.667,0,0,0,2.667,2.667h2a2.667,2.667,0,0,0,2.667-2.667v-2A2.667,2.667,0,0,0,17.667,13ZM19,17.667A1.333,1.333,0,0,1,17.667,19h-2a1.333,1.333,0,0,1-1.333-1.333v-2a1.333,1.333,0,0,1,1.333-1.333h2A1.333,1.333,0,0,1,19,15.667Z"
        transform="translate(-4.333 -4.333)"
        fill="#fff"
      />
    </svg>
  )
}
export const TrackingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="24px"
      height="24px"
      x="0"
      y="0"
      viewBox="0 0 24 24"
    >
      xml:space="preserve"
      <g>
        <path
          d="M12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z"
          fill="#ffffff"
          data-original="#000000"
        />
        <path
          d="M12,24a5.271,5.271,0,0,1-4.311-2.2c-3.811-5.257-5.744-9.209-5.744-11.747a10.055,10.055,0,0,1,20.11,0c0,2.538-1.933,6.49-5.744,11.747A5.271,5.271,0,0,1,12,24ZM12,2.181a7.883,7.883,0,0,0-7.874,7.874c0,2.01,1.893,5.727,5.329,10.466a3.145,3.145,0,0,0,5.09,0c3.436-4.739,5.329-8.456,5.329-10.466A7.883,7.883,0,0,0,12,2.181Z"
          fill="#ffffff"
          data-original="#000000"
        />
      </g>
    </svg>
  )
}

export const AdmissionsIcon = () => {
  return (
    <svg
      id="user-add"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_1381"
        data-name="Path 1381"
        d="M20.667,10H19.333V8.667a.667.667,0,1,0-1.333,0V10H16.667a.667.667,0,0,0,0,1.333H18v1.333a.667.667,0,1,0,1.333,0V11.333h1.333a.667.667,0,0,0,0-1.333Z"
        transform="translate(-5.333 -2.667)"
        fill="#fff"
      />
      <path
        id="Path_1382"
        data-name="Path 1382"
        d="M7,8A4,4,0,1,0,3,4,4,4,0,0,0,7,8ZM7,1.333A2.667,2.667,0,1,1,4.333,4,2.667,2.667,0,0,1,7,1.333Z"
        transform="translate(-1 0)"
        fill="#fff"
      />
      <path
        id="Path_1383"
        data-name="Path 1383"
        d="M6,14a6.007,6.007,0,0,0-6,6,.667.667,0,1,0,1.333,0,4.667,4.667,0,1,1,9.333,0A.667.667,0,0,0,12,20,6.007,6.007,0,0,0,6,14Z"
        transform="translate(0 -4.667)"
        fill="#fff"
      />
    </svg>
  )
}

export const RoutesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="map-marker"
        d="M12,12A4,4,0,1,0,8,8a4,4,0,0,0,4,4Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,12,6Zm8.66,3.157-.719-.239a7.992,7.992,0,1,0-15.855.174,5.045,5.045,0,0,0-2.548,1.3A4.946,4.946,0,0,0,0,14v4.075a5.013,5.013,0,0,0,3.6,4.8l2.87.9A4.981,4.981,0,0,0,7.959,24a5.076,5.076,0,0,0,1.355-.186l5.78-1.71a2.987,2.987,0,0,1,1.573,0l2.387.8A4,4,0,0,0,24,19.021V13.872a5.015,5.015,0,0,0-3.34-4.716ZM7.758,3.762a6.009,6.009,0,0,1,8.495,8.5L12.7,15.717a.992.992,0,0,1-1.389,0l-3.553-3.44a6.04,6.04,0,0,1,0-8.515ZM22,19.021a1.991,1.991,0,0,1-.764,1.572,1.969,1.969,0,0,1-1.626.395L17.265,20.2a5.023,5.023,0,0,0-2.717-.016L8.764,21.892a3,3,0,0,1-1.694-.029l-2.894-.9A3.013,3.013,0,0,1,2,18.075V14a2.964,2.964,0,0,1,.92-2.163,3.024,3.024,0,0,1,1.669-.806A8.021,8.021,0,0,0,6.354,13.7l3.567,3.453a2.983,2.983,0,0,0,4.174,0l3.563-3.463a7.962,7.962,0,0,0,1.813-2.821l.537.178A3.006,3.006,0,0,1,22,13.872Z"
        fill="#fff"
      />
    </svg>
  )
}
export const RegistrationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="user-add"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_1381"
        data-name="Path 1381"
        d="M20.667,10H19.333V8.667a.667.667,0,1,0-1.333,0V10H16.667a.667.667,0,0,0,0,1.333H18v1.333a.667.667,0,1,0,1.333,0V11.333h1.333a.667.667,0,0,0,0-1.333Z"
        transform="translate(-5.333 -2.667)"
        fill="#fff"
      />
      <path
        id="Path_1382"
        data-name="Path 1382"
        d="M7,8A4,4,0,1,0,3,4,4,4,0,0,0,7,8ZM7,1.333A2.667,2.667,0,1,1,4.333,4,2.667,2.667,0,0,1,7,1.333Z"
        transform="translate(-1 0)"
        fill="#fff"
      />
      <path
        id="Path_1383"
        data-name="Path 1383"
        d="M6,14a6.007,6.007,0,0,0-6,6,.667.667,0,1,0,1.333,0,4.667,4.667,0,1,1,9.333,0A.667.667,0,0,0,12,20,6.007,6.007,0,0,0,6,14Z"
        transform="translate(0 -4.667)"
        fill="#fff"
      />
    </svg>
  )
}
export const PaymentsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18"
      viewBox="0 0 24 18"
    >
      <g id="credit-card" transform="translate(0 -3)">
        <circle
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(4 14)"
          fill="#fff"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M19,3H5A5.006,5.006,0,0,0,0,8v8a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V8a5.006,5.006,0,0,0-5-5ZM5,5H19a3,3,0,0,1,3,3H2A3,3,0,0,1,5,5ZM19,19H5a3,3,0,0,1-3-3V10H22v6A3,3,0,0,1,19,19Z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
export const ReportsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.333"
      height="16"
      viewBox="0 0 13.333 16"
    >
      <path
        id="file"
        d="M13.966,3.691,11.643,1.367A4.639,4.639,0,0,0,8.343,0H5.333A3.337,3.337,0,0,0,2,3.333v9.333A3.337,3.337,0,0,0,5.333,16H12a3.337,3.337,0,0,0,3.333-3.333V6.99a4.634,4.634,0,0,0-1.367-3.3Zm-.943.943a3.322,3.322,0,0,1,.521.7H10.667A.667.667,0,0,1,10,4.667V1.789a3.34,3.34,0,0,1,.7.52ZM14,12.667a2,2,0,0,1-2,2H5.333a2,2,0,0,1-2-2V3.333a2,2,0,0,1,2-2h3.01c.109,0,.215.021.323.031v3.3a2,2,0,0,0,2,2h3.3c.01.108.031.213.031.323Z"
        transform="translate(-2 0)"
        fill="#fff"
      />
    </svg>
  )
}
export const ActivitiesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="clock_5_"
      data-name="clock (5)"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_1791"
        data-name="Path 1791"
        d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,14.667A6.667,6.667,0,1,1,14.667,8,6.667,6.667,0,0,1,8,14.667Z"
        fill="#fff"
      />
      <path
        id="Path_1792"
        data-name="Path 1792"
        d="M10.386,6a.667.667,0,0,0-.667.667V9.55L7.472,10.958a.668.668,0,0,0,.708,1.133l2.56-1.6a.667.667,0,0,0,.313-.572V6.667A.667.667,0,0,0,10.386,6Z"
        transform="translate(-2.386 -2)"
        fill="#fff"
      />
    </svg>
  )
}
export const ArchivesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="24"
      height="24"
      x="0"
      y="0"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M17,0H7A5.006,5.006,0,0,0,2,5V19a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,17,0ZM7,2H17a3,3,0,0,1,3,3v6H4V5A3,3,0,0,1,7,2ZM17,22H7a3,3,0,0,1-3-3V13H20v6A3,3,0,0,1,17,22Z"
          fill="#ffffff"
          data-original="#000000"
        />
        <path
          d="M11,7h2a1,1,0,0,0,0-2H11a1,1,0,0,0,0,2Z"
          fill="#ffffff"
          data-original="#000000"
        />
        <path
          d="M13,17H11a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z"
          fill="#ffffff"
          data-original="#000000"
        />
      </g>
    </svg>
  )
}
export const ConfigurationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g id="settings-sliders" transform="translate(0 0)">
        <path
          id="Path_17828"
          data-name="Path 17828"
          d="M.667,3.167H2.491a2.485,2.485,0,0,0,4.8,0h8.046a.667.667,0,1,0,0-1.333H7.287a2.485,2.485,0,0,0-4.8,0H.667a.667.667,0,1,0,0,1.333ZM4.889,1.333A1.167,1.167,0,1,1,3.722,2.5,1.167,1.167,0,0,1,4.889,1.333Z"
          fill="#fff"
        />
        <path
          id="Path_17829"
          data-name="Path 17829"
          d="M15.333,10.083H13.509a2.485,2.485,0,0,0-4.8,0H.667a.667.667,0,1,0,0,1.333H8.713a2.485,2.485,0,0,0,4.8,0h1.824a.667.667,0,1,0,0-1.333Zm-4.222,1.833a1.167,1.167,0,1,1,1.167-1.167,1.167,1.167,0,0,1-1.167,1.167Z"
          transform="translate(0 -2.75)"
          fill="#fff"
        />
        <path
          id="Path_17830"
          data-name="Path 17830"
          d="M15.333,18.333H7.287a2.485,2.485,0,0,0-4.8,0H.667a.667.667,0,0,0,0,1.333H2.491a2.485,2.485,0,0,0,4.8,0h8.046a.667.667,0,0,0,0-1.333ZM4.889,20.167A1.167,1.167,0,1,1,6.055,19a1.167,1.167,0,0,1-1.167,1.167Z"
          transform="translate(0 -5.5)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
