import { SingleSelect } from '@campxdev/shared'
import { Button, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useImmer } from 'use-immer'

const defaultValues = {
  year: null,
}

function ReportFilters({ close, state, setState }) {
  const [filters, setFilters] = useImmer(defaultValues)

  const handleApplyFilters = () => {
    setState((s) => ({
      ...s,
      filters: {
        ...s.filters,
        year: filters.year,
      },
      filtersApplied: true,
    }))
    close()
  }

  const clearFilters = () => {
    setState((s) => ({
      ...s,
      filters: {
        ...s.filters,
        year: null,
      },
      filtersApplied: false,
    }))
    close()
  }

  useEffect(() => {
    setFilters(state.filters)
  }, [])

  return (
    <>
      <Stack gap={2}>
        <Stack gap={1}>
          <SingleSelect
            label={'Year'}
            name={'year'}
            value={filters.year}
            options={[1, 2, 3, 4, 5, 6]?.map((item) => {
              return { label: item.toString(), value: item.toString() }
            })}
            onChange={(e) => {
              setFilters((s) => {
                s.year = e.target.value
              })
            }}
          />
        </Stack>
        <Stack direction="row" gap={'20px'} marginTop={'20px'}>
          <Button fullWidth size="large" onClick={handleApplyFilters}>
            Apply Filters
          </Button>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            onClick={clearFilters}
          >
            Clear Filters
          </Button>
        </Stack>
      </Stack>
    </>
  )
}

export default ReportFilters
