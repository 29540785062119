import { axios } from '@campxdev/shared'

export async function fetchFeeDuesReport(params) {
  return axios
    .get('/square/transport-registration/fee-dues', {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
}
