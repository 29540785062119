import {
  AppHeader,
  ErrorBoundary,
  Helmet,
  Spinner,
  useAuth,
} from '@campxdev/shared'
import { styled } from '@mui/material'
import { Outlet } from 'react-router-dom'

const headerHeight = '64px'

export const StyledHeaderContainer = styled('header')(() => ({
  zIndex: 300,
  width: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  minHeight: headerHeight,
  background: '#fff',
  boxShadow: '0px 0px 3px #0000001a',
  '& > div': {
    height: headerHeight,
  },
}))

export default function AppLayout() {
  const { data, loading } = useAuth({
    permissionsEndpoint: '/auth-server/auth/my-permissions',
  })

  if (loading) return <Spinner />

  return (
    <>
      <Helmet
        appTitle="Digital Campus | CommuteX"
        favicon={data?.assets?.logo_square}
        user={data?.user}
      />
      <StyledHeaderContainer>
        <AppHeader
          userBoxActions={[]}
          clientLogo={data?.assets?.logo}
          fullName={data?.user?.fullName}
        />
      </StyledHeaderContainer>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </>
  )
}
