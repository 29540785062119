import ApplicationProfile from '@campxdev/shared/src/components/ApplicationProfile/ApplicationProfile'

function People() {
  return (
    <>
      <ApplicationProfile
        title={'People'}
        application={'commute_x'}
        permissions={{
          add: `CAN_MANAGE_COMMUTE_X_PROFILE_PERMISSIONS_ADD`,
          edit: 'CAN_MANAGE_COMMUTE_X_PROFILE_PERMISSIONS_EDIT',
          view: 'CAN_MANAGE_COMMUTE_X_PROFILE_PERMISSIONS_VIEW',
          delete: 'CAN_MANAGE_COMMUTE_X_PROFILE_PERMISSIONS_DELETE',
        }}
      />
    </>
  )
}
export default People
